import {useState} from "react";
import clsx from "clsx";
import Logo from "../../images/logo_nav_white.png";
import {Link} from "react-router-dom";

function Navbar() {
    const [menuOpened, setMenuOpened] = useState(false);
    const [tattooOpened, setTattooOpened] = useState(false);
    const [terminOpened, setTerminOpened] = useState(false);

    const classes = clsx({
        "hidden": !menuOpened,
        "flex": menuOpened
    });

    const classesTattoo = clsx({
        "h-0": !tattooOpened,
        "h-[90px]": tattooOpened,
    });

    const classesTermin = clsx({
        "h-0": !terminOpened,
        "h-[90px]": terminOpened,
    });

    const classesRotateTattoo = clsx({
        "rotate-180": tattooOpened,
        "rotate-0": tattooOpened!
    });

    const classesRotateTermin = clsx({
        "rotate-180": tattooOpened,
        "rotate-0": tattooOpened!
    });

    function handleToggleMenu() {
        setMenuOpened(!menuOpened);
    }

    function handleCloseMenu() {
        if (setMenuOpened) {
            setMenuOpened(false);
        }
    }

    function handleToggleTattoo() {
        setTattooOpened(!tattooOpened);
    }

    function handleToggleTermin() {
        setTerminOpened(!terminOpened);
    }

    return (
        <nav className="tracking-widest bg-gradient-to-b from-gray-900 to-gray-800 lg:text-white">
            <div className="mx-auto lg:container">
                <div className="grid grid-cols-4 text-sm xl:text-base uppercase lg:grid-cols-3 min-h-32">
                    {/*menu left side */}
                    <ul className={classes + " bg-white lg:bg-transparent flex-col lg:flex lg:flex-row col-start-1 row-start-2 col-span-5 lg:row-start-1 lg:col-span-1 lg:col-start-1 lg:pl-4 xl:pl-2 2xl:pl-24 lg:items-center lg:justify-start"}>
                        <li className="lg:h-full">
                            <Link onClick={handleToggleMenu} className="block items-center py-3 pr-4 pl-4 mt-2 border-b border-gray-100 lg:h-full lg:flex lg:mt-0 lg:pl-0 lg:pr-3 xl:px-5 hover:bg-gray-100 lg:hover:bg-petrol-400 lg:hover:font-bold lg:border-none" to="/">Home</Link>
                        </li>
                        <li className="flex relative flex-col group lg:h-full lg:justify-center lg:items-center">
                            <div className="flex lg:items-center lg:h-full">
                                <button onClick={handleToggleTattoo} className="font-WorkSans tracking-widest block flex justify-between py-3 pr-4 pl-4 w-full uppercase border-b border-gray-100 lg:group-hover:font-bold lg:h-full lg:items-center lg:mt-0 lg:px-3 xl:px-5 hover:bg-gray-100 lg:group-hover:bg-petrol-400 lg:hover:font-bold  lg:border-none">
                                    Tattoo
                                    <svg xmlns="http://www.w3.org/2000/svg" className={classesRotateTattoo + " lg:block h-5 w-5 transition-transform transform lg:group-hover:rotate-180"} viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            {/* Tattoo submenu */}
                            <div className={classesTattoo + " w-full lg:w-[220px] transition-height transition-200 lg:h-0 text-black overflow-hidden lg:group-hover:h-[89px] xl:group-hover:h-[98px] lg:block lg:absolute lg:z-20 lg:top-32 lg:bg-petrol-400 lg:rounded-b lg:shadow-xl lg:text-gray-100"}>
                                <ul className="normal-case">
                                    <li>
                                        <Link onClick={handleToggleMenu} className="hover:font-bold  block py-3 pl-8 border-b lg:h-full lg:px-4 lg:py-3 " to="/tattoo">Meine Arbeiten</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleToggleMenu} className="hover:font-bold block py-3 pl-8 border-b lg:border-none lg:h-full lg:px-4 lg:py-3 " to="/wannados">Wanna Dos</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="flex relative flex-col group lg:h-full lg:justify-center lg:items-center">
                            <Link onClick={handleToggleMenu} className="block items-center py-3 pr-4 pl-4 border-b border-gray-100 border-b lg:h-full lg:flex lg:mt-0 lg:px-3 xl:px-5 hover:bg-gray-100 lg:hover:bg-petrol-400 lg:hover:font-bold  lg:border-none" to="/piercing">Piercing</Link>
                        </li>
                    </ul>
                    {/* logo */}
                    <Link onClick={handleCloseMenu} className="flex col-span-5 col-start-1 row-start-1 self-center py-2 ml-4 h-32 lg:ml-0 lg:col-span-1 lg:col-start-2 lg:justify-self-center items-center" to="/">
                        <img alt="Firmenlogo Romy" src={Logo} className="h-2/3 lg:h-3/5 xl:h-4/6" />
                    </Link>
                    {/* menu right side */}
                    <ul className={classes + " bg-white lg:bg-transparent flex-col lg:flex lg:flex-row col-start-1 row-start-3 lg:row-start-1 col-span-5 lg:col-span-1 xl:pr-2 lg:pr-4 2xl:pr-24 lg:items-center lg:justify-end"}>
                        <li className="flex relative flex-col group lg:h-full lg:justify-center lg:items-center">
                            <div className="flex lg:items-center lg:h-full">
                                <button onClick={handleToggleTermin} className="font-WorkSans tracking-widest block flex justify-between py-3 pr-4 pl-4 w-full uppercase border-b border-gray-100 lg:group-hover:font-bold lg:h-full lg:items-center lg:mt-0 lg:px-3 xl:px-5 hover:bg-gray-100 lg:group-hover:bg-petrol-400 lg:hover:font-bold  lg:border-none">
                                    Termin
                                    <svg xmlns="http://www.w3.org/2000/svg" className={classesRotateTermin + " lg:block h-5 w-5 transition-transform transform lg:group-hover:rotate-180"} viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            {/* Termin submenu */}
                            <div className={classesTermin + " w-full lg:w-[220px] transition-height transition-200 lg:h-0 text-black overflow-hidden lg:group-hover:h-[89px] xl:group-hover:h-[98px] lg:block lg:absolute lg:z-20 lg:top-32 lg:bg-petrol-400 lg:rounded-b lg:shadow-xl lg:text-gray-100"}>
                                <ul className="normal-case">
                                    <li>
                                        <Link onClick={handleToggleMenu} className="hover:font-bold block py-3 pl-8 border-b lg:h-full lg:px-4 lg:py-3 " to="/terminTattoo">Tattoo</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleToggleMenu} className="hover:font-bold block py-3 pl-8 border-b lg:border-none lg:h-full lg:px-4 lg:py-3 " to="/terminPiercing">Piercing</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="flex relative flex-col group lg:h-full lg:justify-center lg:items-center">
                            <Link onClick={handleToggleMenu} className="block items-center py-3 pr-4 pl-4 border-b border-gray-100 lg:h-full lg:flex lg:mt-0 lg:pl-3 lg:pr-0 xl:px-5 hover:bg-gray-100 lg:hover:bg-petrol-400 lg:hover:font-bold lg:border-none" to="/anfahrt">Anfahrt</Link>
                        </li>
                    </ul>
                    {/* mobile menu button */}
                    <div className="flex col-span-1 col-start-5 row-start-1 justify-end items-center h-full text-gray-100">
                        <button onClick={handleToggleMenu} type="button" className="font-WorkSans tracking-widest block lg:hidden">
                            {!menuOpened && <svg xmlns="http://www.w3.org/2000/svg" className="mr-4 w-8 h-8 fill-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>}
                            {menuOpened && <svg xmlns="http://www.w3.org/2000/svg" className="mr-4 w-8 h-8 fill-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>}
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
